import * as React from 'react'
import Subnav from './subnav'

const HiwSubnav = () => (
  <Subnav
    isSticky={true}
    links={[
      {
        to: '/how_it_works/online_car_buying',
        text: 'Online Car Buying'
      },
      {
        to: '/how_it_works/remote_car_selling',
        text: 'Remote Car Selling'
      },
      {
        to: '/how_it_works/showroom_tools',
        text: 'Showroom Tools'
      },
      {
        to: '/how_it_works/manager_tools',
        text: 'Manager Tools'
      }
    ]}
  />
)

export default HiwSubnav
