import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'
import HiwSubnav from '../../components/hiw_subnav'
import { StaticImage } from 'gatsby-plugin-image'
import NumberedScroll from '../../components/numbered_scroll'
import AssignRoles1Image from '../../images/HIW_Manager_AssignRoles_01.png'
import AssignRoles2Image from '../../images/HIW_Manager_AssignRoles_02.png'
import ViewDealsImage from '../../images/HIW_Manager_ViewDeals.png'
import ViewReportsImage from '../../images/HIW_Manager_ViewReports.png'
import Box from '../../components/box'

const HiwManagerToolsPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Manager Tools'
      description=''
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/HIW_ManagerTools_Hero.jpg'
        placeholder='blurred'
        alt=''
        className='absolute inset-0'
      />
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Manager Tools
        </h1>
        <p className='text-md sm:text-lg mb-0' style={{ maxWidth: '42em' }}>
          Dealership sales managers want visibility and control, and Express Storefront delivers both. Our manager tools enable them to see all customer and agent activity, protect their dealership’s profitability and even offer deal assistance anywhere they have an internet connection.
        </p>
      </Container>
    </div>
    <HiwSubnav />
    <div className='py-3 sm:py-4'>
      <Container size='lg'>
        <NumberedScroll
          items={[
            {
              title: 'Assign Agent Roles',
              desc: 'Managers can give their team as little or much autonomy as they’re comfortable with by assigning roles and responsibilities to all agents in Roadster Admin.',
              image: <>
                <img loading='lazy' alt='' src={AssignRoles2Image} />
                <Box
                  as='img'
                  alt=''
                  src={AssignRoles1Image}
                  loading='lazy'
                  css={{
                    willChange: 'transform, opacity',
                    transform: 'translateX(-40%) scale(90%)',
                    transitionProperty: 'transform, opacity',
                    transitionDuration: '500ms',
                    transitionDelay: '500ms'
                  }}
                />
              </>
            },
            {
              title: 'Discount or Mark Up Your Vehicles',
              desc: 'Provide customers with pricing transparency while also protecting your profitability. Using our Vehicle Pricing Tool, managers can discount or markup vehicles down to the individual VIN level based on age, available incentives, invoice pricing, etc.'
            },
            {
              title: 'Merchandise Your F&I Menu',
              desc: 'To protect your backend gross, we enable managers to prominently merchandise F&I products throughout the Express Store experience. If customers don’t add a product at the start of their order, they’ll be prompted to do so again during checkout.'
            },
            {
              title: 'View Deals & Make Adjustments',
              desc: 'Deal View within Roadster Admin gives managers full visibility into every saved deal for customers who are “checked in” to the showroom. From there, managers can make real-time adjustments to protect their dealership’s profitability.',
              image: ViewDealsImage
            },
            {
              title: 'Produce The Final Deal Sheet',
              desc: 'Our platform makes it easy to request additional documents, such as stips or title and registration for an existing vehicle. They automatically append to the electronic deal jacket, which managers can review before producing the final deal sheet.'
            },
            {
              title: 'Manage Vehicle Delivery',
              desc: 'Offer customers the modern convenience of home vehicle delivery through your Express Store. Your Dealer Success Manager can help you create delivery pricing rules based on the customer’s distance from your dealership.'
            },
            {
              title: 'View Reports & Optimize',
              desc: 'To help managers optimize their results, we make it easy for them to keep a close eye on their Express Store performance within Roadster Admin. They can see how customers are engaging with the platform, get a helicopter view of the sales process and oversee their agent utilization to help them maximize their efficiency.',
              image: ViewReportsImage
            }
          ]}
        />
      </Container>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default HiwManagerToolsPage
